<template>
	<div class="block-inner">
		<header @change="$emit('edit')">
			<div>
				<RichEditor v-model="block.content" :inline="true" :block-id="block.id" :paste-tags="false" placeholder="Write a question" @input="$emit('edit')" />
			</div>
		</header>

		<div class="input-group repeat">
			<draggable v-bind="dragOptions" :list="options" @end="$emit('edit')">
				<div v-for="(option, index) in options" :key="index" class="input-group-row" @change="$emit('edit')">
					<IconMoveVertical :width="16" :height="16" class="move-option" />
					<input v-model="option.label" type="text" placeholder="Add a label..." />
					<BoolInput v-model="option.isCorrect" :checked="option.isCorrect" label="Correct" :name="optionName(option)" />
					<BaseButton type="primary rounded" @click="removeInput(index)">
						<IconCross :width="12" :height="12" />
					</BaseButton>
				</div>
			</draggable>
			<BaseButton type="primary rounded" @click="addInput">
				<IconAdd :width="16" :height="16" />
			</BaseButton>
		</div>
	</div>
</template>

<script>
	import IconAdd from '../icons/IconAdd';
	import IconCross from '../icons/IconCross';
	import IconMoveVertical from '../icons/IconMoveVertical';
	import BaseButton from '../BaseButton';
	import BoolInput from '../BoolInput';
	import {v4} from 'uuid';
	import draggable from 'vuedraggable';
	import RichEditor from '../RichEditor';

	export default {
		components: {
			IconAdd,
			IconCross,
			IconMoveVertical,
			BaseButton,
			BoolInput,
			draggable,
			RichEditor
		},

		props: {
			block: {
				type: Object,
				required: true
			}
		},

		data() {
			return {
				dragOptions: {
					animation: 200,
					disabled: false,
					direction: 'vertical',
					handle: '.move-option',
					tag: 'section',
					class: {
						'input-group-rows': true
					}
				},
				optionsGroup: ''
			};
		},

		computed: {
			options() {
				return this.block.options && this.block.options[this.optionsGroup] ? this.block.options[this.optionsGroup] : [];
			}
		},

		created() {
			if(this.block.options && Object.keys(this.block.options).length) {
				this.optionsGroup = Object.keys(this.block.options).pop();
			}
			else {
				this.optionsGroup = v4();
				this.$set(this.block, 'options', {[this.optionsGroup]: []});
			}

			if(!this.block.meta.sortOptions) {
				this.$set(this.block.meta, 'sortOptions', 'manual');
			}
		},

		methods: {
			addInput() {
				this.options.push(this.defaultOption());
			},
			removeInput(index) {
				this.options.splice(index, 1);
				this.$emit('edit');
			},
			optionName(option) {
				return this.block.meta.allowMultiple ? option.id : this.block.id + '-correct';
			},
			defaultOption() {
				return {
					id: v4(),
					label: '',
					isCorrect: false
				};
			}
		}
	};
</script>

<style lang="scss" scoped>
	header {
		margin-bottom: $default_padding/2;
	}

	.input-group {
		display: flex;
		flex-flow: column nowrap;

		.input-group-row {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			margin-bottom: 10px;

			input,
			.bool-input {
				margin: 0;
			}

			input,
			.button,
			.bool-input {
				margin-left: 10px;
			}

			.button {
				button {
					padding: 5px;
				}
			}
		}

		& > .button {
			align-self: center;
		}

		button {
			background-color: $color__dark;

			&:hover {
				background-color: rgba($color: $color__dark, $alpha: 0.8);
			}
		}
	}
</style>